import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import * as V from '../../styles/variables'

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${V.MaxSize.lg};
  padding-left: ${V.Space.default};
  padding-right: ${V.Space.default};
`

export const Greetings = styled.p`
  font-family: "Source Code Pro", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 1rem;
  color: var(--greetings) !important;
  margin: 0px;
`

export const Name = styled.h1`
  font-weight: 100 !important;
`

export const ReadMore = styled(AniLink)`
  background: var(--background);
  border: 1px solid var(--borders);
  border-radius: 6px;
  color: var(--shellText);
  font-size: 1.135rem;
  font-weight: 100;
  letter-spacing: 0.02em;
  //line-height: 32px;
  margin-top: 1rem;
  padding: 0 10px;
  text-decoration: none;
  text-transform: none;
  transition: opacity 0.5s;
  &:hover {
    opacity: 0.6;
  }
`