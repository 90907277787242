import React from 'react'
import ReactGA from 'react-ga'

import { useStaticQuery, graphql } from 'gatsby'

import ButtonLink from '../components/ButtonLink'
import Layout from '../components/layout'
import SEO from '../components/seo'
//import BlogList from '../components/BlogList'
import Author from '../components/Author'

import { IntroBioContent } from '../styles/styledindex'

const trackLanguageClick = () => {
  ReactGA.event({
    category: 'Home',
    action: 'click',
    label: `Home - Go to portuguese`
  })
}

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            descriptionEn
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO lang="en" title='Home' description={site.siteMetadata.descriptionEn} />
      <IntroBioContent>
        <ButtonLink
          to="/"
          title="View in portuguese" 
          onClick={() => trackLanguageClick()} >
          Ver em português
        </ButtonLink>

        <Author language="en" />
      </IntroBioContent>
    </Layout>
  )
}

export default IndexPage