import React from 'react'
import PropTypes from 'prop-types'

import getThemeColor from "../../utils/getThemeColor"

import * as S from './styled'

const ButtonLink = ({ children, to, title }) => {
  return (
    <S.ButtonLink
      to={to}
      title={title}
      cover
      direction="down"
      duration={1}
      bg={getThemeColor()}>
      { children }
    </S.ButtonLink>
  )
}

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default ButtonLink