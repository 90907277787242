import React from 'react'
import PropTypes from 'prop-types'

import getThemeColor from "../../utils/getThemeColor"

//import Container from '../Container' TODO

import * as S from './styled'

const Author = ({ language, fullText }) => {
  return (
      <div itemScope itemType="http://schema.org/Person">
      { language === 'en' ? (
        <>
          <br /><br /><br />
          <S.Greetings>Hi, my name is</S.Greetings>

          <h1>
            <strong>Lucas Seixas.</strong>
            <br/>
            Developer and SAP ABAP Consultant
          </h1>
          <br />
          <p>
            For more than 5 years in professional contact with SAP and Development in general,
            my goal is to stay in touch and use the newest technologies. <br />
            A hobbyist for Unreal Engine 4 and Unity, I love the game development area and passionate about AI.<br />
            <S.ReadMore to="/about/"
              title="About me"
              cover
              direction="right"
              duration={1}
              bg={getThemeColor()}
              >
              Read more...
            </S.ReadMore>
          </p>

          {fullText && (
            <>
              <p>TODO pass all HI page info here and rearange about page totally, using only author component passing true in fullText</p>
            </>
          )}
        </>
      ) : (
        <>
          <br /><br /><br />
          <S.Greetings>Olá, meu nome é</S.Greetings>

          <h1>
            <strong>Lucas Seixas.</strong>
            <br/>
            Desenvolvedor e Consultor SAP ABAP
          </h1>
          <br />
          <p>
            Por mais de 5 anos em contato profissional com SAP e Desenvolvimento no geral, 
            meu objetivo é estar em contato e utilizar as mais novas tecnologias.<br />
            Hobbysta da Unreal Engine 4 e Unity, adoro a área de gamedev e sou apaixonado por IA. <br />
            <S.ReadMore to="/about/"
              title="Sobre mim"
              cover
              direction="right"
              duration={1}
              bg={getThemeColor()}
              >
              Leia mais...
            </S.ReadMore>
          </p>

          {fullText && (
            <>
              <p>Sou lorem ipsum</p>
            </>
          )}
        </>
      )}

      {fullText && (
        <>
          <ul>
            <li><a itemProp="owns" href="https://github.com/Seixas" target="_blank" rel="noopener noreferrer" title="Seixas">SeixasDev</a></li>
          </ul>
        </>
      )}
      </div>
  )
}

Author.propTypes = {
  language: PropTypes.string,
  fullText: PropTypes.bool
}

export default Author