import styled from 'styled-components'
import media from 'styled-media-query'

//import * as V from '../styles/variables'


export const IntroBioContent = styled.section`
  margin: auto;
  max-width: 75rem;
  padding: 2rem 5rem;
  ${media.lessThan('medium')`
    padding: .5rem .2rem 1rem .75rem;
    max-width: 100%;
  `}
  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    color: var(--shellText);
    font-size: 1.235rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.069rem;
    //padding: 0 1.4rem;
    ${media.lessThan('medium')`
      padding: 0 1rem;
      word-break: break-word;
    `}
  }
  /*p {
    display: block;
    text-align: justify;
    margin: 0 auto 1.6rem;
  }*/
  h1,
  h2,
  h3,
  h4,
  h5 {
    //margin: 2.4rem auto 1rem;
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  /*img {
    display: block;
    max-width: 100%;
    margin: 1.875rem auto;
  }*/
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;
    ${media.lessThan('medium')`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: var(--postColor);
    border-left: 0.3rem solid var(--highlight);
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid var(--borders);
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
    letter-spacing: 0.069rem;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.8rem;
    ${media.lessThan('medium')`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan('medium')`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan('medium')`
      font-size: 1.125rem;
    `}
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  /*img{
    height: auto !important;
    display: block;
    max-width: 322px;
    max-height: 321px;
    margin: auto;
    border-radius: 2rem;
    //filter: drop-shadow(1rem 1rem 15rem rgba(2, 2, 2, .5));
  }*/
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
    border-radius: .5rem;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    border-radius: .5rem;
    &.lazyload {
      opacity: 0;
      border-radius: .5rem;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
      border-radius: .5rem;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;
    ${media.lessThan('medium')`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    //border-bottom: 1px dashed var(--highlight);
    color: var(--menuHover);
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: var(--postColor);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  abbr {
    border-bottom: 1px dotted;
  }
  em{
    &.personalposition{
      //opacity: 0.85;
      //filter: alpha(opacity=85);
      font-size: 2.3rem;
    }
  }
`
